export default {
  page: {
    title: {
      base: 'Autopay ödeme ağ geçidi',
      start: 'Bir ödeme yöntemi seçme',
      visadata: 'Visa Mobile',
      continue: 'Devam - Bir ödeme yöntemi seçme',
      wait: 'Beklenti',
      confirmation: 'Ödeme onaylama',
      thankYou: 'Ödeme için onay',
      error: 'Sayfa hatası',
      errorNotFound: 'Sayfa bulunamadı',
      secureBack: 'Sayfa yükleme',
      maintenance: 'Sayfa bakımda',
      regulationsApproval: 'Düzenlemeler ve Gizlilik Politikası',
      toTransferData: 'havalesi ayrıntıları',
      sessionTimeout: 'Oturumun bitiminden sonraki sayfa',
      paywayError: 'Ödeme kanalı kullanılamıyor'
    }
  },
  header: {
    availabilityAdjust: {
      increaseFontSize: 'Yazı tipi boyutunu artır',
      decreaseFontSize: 'Yazı tipi boyutunu küçült',
      changeContrast: 'Kontrastı değiştir',
      changeLanguage: 'Site dilini değiştir',
      skipToContent: 'İçeriğe geç',
      skipToContentLabel: 'Ana içeriğe geç',
      logoTitle: 'Site logosu'
    },
    sessionTimer: {
      timeOut: 'Oturumun süresi doldu',
      sessionEnd: 'Oturumun sona ermesine kalan süre ',
      label: 'Oturum süresini uzat',
      button: 'Uzat'
    }
  },
  footer: {
    text1: 'Çevrimiçi ödemeler veya diğer hizmetlerimizle ilgili başka sorularınız mı var? <a href="https://pomoc.autopay.pl/?pk_campaign=paywall&pk_kwd=start&pk_medium=hyperlink" class="footer-text-link" target="_blank" title="Yeni bir pencerede açılır" rel="noreferrer">Yardım Panelini Kullanın</a>',
    text2: {
      part1: `Kişisel verilerin yöneticisi Autopay S.A.'dır`,
      part2: `ul. Powstańców Warszawy 6, 81-718 Sopot. Kişisel verilerin sağlanması isteğe bağlıdır, ancak sunulan başvuruyu tamamlamak için gereklidir. Kişisel verilerin işlenmesinin hukuki dayanağı, amacı, süresi ve Kullanıcı hakları ile kişisel veri işleme esaslarına ilişkin diğer önemli bilgiler burada bulunmakta <a href="{privacyPolicyUrl}" class="footer-text-link" target="_blank" title=" Gizlilik Politikası belgesini açın (128 KB, PDF)" rel="noreferrer"> Autopay S.A.'nın gizlilik politikası.</a>`
    },
    linkShowMoreTitle: 'Daha fazla bilgi görüntüle',
    linkShowMoreText: 'Kişisel Veri Yöneticisi hakkında daha fazla bilgi',
    linkShowLessTitle: 'İlave bilgileri gizle',
    linkShowLessText: 'Kişisel Veri Yöneticisi hakkında daha az bilgi'
  },
  paywall: {
    header: 'Ödeme yöntemini seçin',
    info: {
      paywayNotAvailable: 'Üzgünüz, ancak seçilen ödeme yöntemi şu anda cihazınızda mevcut değil gibi görünüyor.',
      applePayNotAvailable: 'Üzgünüz ama görünüşe göre Apple Pay şu anda cihazınızda kullanılamıyor.',
      googlePayNotAvailable: 'Üzgünüz, ancak görünüşe göre Google Pay şu anda cihazınızda kullanılamıyor.'
    },
    paywayGroup: {
      inactiveMessage: 'Ödeme şu anda kullanılamıyor',
      notice: {
        novelty: 'YENİ',
        promotion: 'PROMOCJA',
        recommended: 'ÖNERİLEN',
        mastercard: 'İNTERNETTE EN GÜVENLİ ÖDEME',
        mastercardC2P: 'İNTERNETTE EN GÜVENLİ ÖDEME',
        blik: 'BLIKOMANIA - Kayıt olun ve elmas kazanın!',
        visaMobile: 'Sorunsuz ve güvenli öde'
      },
      bank: {
        many: 'bankalar',
        one: 'banka',
        two: 'bankalar'
      },
      wallet: {
        many: 'cüzdanlar',
        one: 'cüzdan',
        two: 'cüzdanlar'
      },
      otp: {
        many: 'yollar',
        one: 'yol',
        two: 'yollar'
      },
      blikPayLater: {
        info: 'Daha fazlasını bul'
      }
    },
    paywayList: {
      label: 'Ödeme türleri grubu',
      linkSelectOther: 'Başka bir yöntem seçin'
    },
    paywaySingle: {
      accountAtAnotherBank: 'Mam konto w innym banku'
    },
    paywaySwitch: {
      methodShortSingle: 'Tek seferlik',
      methodLongSingle: 'Tek seferlik ödemeler',
      methodShortAuto: 'Yenilenen',
      methodLongAuto: 'Yenilenen ödemeler'
    },
    form: {
      payway: {
        label: ' Ödeme yöntemi seçin'
      },
      card: {
        label: 'Kart ayrıntılarını girin',
        iframe: {
          title: 'Doldurulacak kredi kartı bilgilerini içeren form'
        }
      },
      visaMobile: {
        iframe: {
          title: 'tamamlanacak telefon numarası formu'
        },
        status: {
          pending: {
            statusText: 'teyit bekleme',
            header: 'Uygulamada ödemeyi teyit et',
            descriptionText1: 'Ödemeyi Visa Mobile uygulamasında veya Visa Mobile hizmeti aktif olan banka uygulamasında teyit edersin',
            descriptionText2: 'Uygulamada işlemi teyit ettikten sonra ödeme neticesini bekle',
            descriptionText3: 'işlemin iptali devem eder',
            cancelButton: 'ödemeyi iptal et'
          },
          success: {
            statusText: 'Alışveriş yaptığın için teşekkür ederiz',
            descriptionText: 'Ödeme olumlu tamamlandı'
          },
          error: {
            statusText: 'Visa Mobile ödemesi için kullanılan ödeme kartında  yeteri kadar paran olup olmadığını kontrol et',
            descriptionText: 'Ödeme reddedildi'
          }
        },
        apps: 'Visa Mobile ödemeleri gerçekleştiren uygulamalar',
        help: 'Yardıma ihtiyacın var mı?',
        info: 'Daha çok bilgi elde et',
        secure: 'SSL şifreleme sistemi sayesinde emniyetli ödeme',
        modal2: {
          header: 'Ödemeyi teyit etmek için Visa Mobile uygulamasını veya aktif Visa Mobile hizmetin olan banka uygulamasını aç',
          listText1: 'Visa Mobile uygulaması indirilmiş durumda veya bankanın mobil uygulamasında aktif Visa Mobile hizmetin var',
          listText2: 'Visa Mobile uygulamasında veya banka uygulamasında aktif Visa Mobile bildiri hizmetin var',
          listText3: 'bildirdiğin numara doğrudur: <strong class="no-wrap">+{prefix} {phone}</strong>',
          listText4: 'Aşağıdaki hususları kontrol et...'
        }
      },
      click2pay: {
        loaderText: '<strong>Click to Pay</strong>de<br>e-posta adresinizi arıyoruz',
        saveCard: {
          text1: 'Kartınızı kaydedin',
          text2: 'Verileriniz <strong>Mastercard Click to Pay</strong>ye güvenle kaydedilecek',
          link: 'Daha fazla bilgi edinin'
        },
        email: {
          labelForClick2Pay: 'Ödeme bildirimleri için e-posta adresi'
        },
        cardSelect: {
          selected: 'Seçili',
          select: 'Seç',
          payOther: 'Kart ayrıntılarını girin',
          back: 'Geri'
        },
        cardSelected: {
          header: 'Click to Pay kayıtlı bir kartı kullan',
          date: 'geçerlilik tarihi',
          buttonChange: 'Değiştir'
        },
        codeForm: {
          description: 'Click to Pay e-posta adresinizi bulduk.',
          codeSentEmail: 'Hesabınıza giriş yapmak için {email} adresine gönderdiğimiz tek seferlik kodu girin.',
          codeSentPhone: 'Hesabınıza giriş yapmak için {phone} adresine gönderdiğimiz tek seferlik SMS kodunu girin.',
          codeLabel: 'Kodu girin',
          codePlaceholder: 'kodu girin',
          codeLabelFlow: 'Kod',
          codeSendLabel: 'Yeni kod gönder',
          codeSendAriaLabel: 'üzerinden',
          codeSendAriaLabel2: 'veya',
          buttonSendNewSms: 'SMS',
          buttonSendNewEmail: 'e-posta',
          trustedLabel: 'Bu cihazı güvenilenler listenize ekleyin<br><span>Bir dahaki sefere bu cihazı tanıyacağız ve giriş yapmadan ödeme yapacaksınız</span>',
          buttonSubmit: 'Sonraki',
          buttonChangeCard: 'Kart ayrıntılarını girin'
        },
        info: {
          description: 'Kartla ödeme şifreli ve güvenlidir. İşlem, bankanın web sitesindeki 3DSecure kullanılarak yetkilendirilecek.'
        },
        loginForm: {
          emailLabel: 'Click to Pay giriş yapmak için e-posta adresinizi girin',
          emailPlaceholder: 'e-posta adresi',
          emailLabelFlow: 'E-posta',
          buttonSubmit: 'Giriş yap',
          buttonBack: 'Geri'
        },
        newUserForm: {
          header: 'Sekmeyi Click to Pay\'ye kaydet',
          description: 'Kartınızı kaydetmek için birkaç ayrıntıya daha ihtiyacımız var.',
          emailLabel: 'E-posta adresini girin',
          emailPlaceholder: 'e-posta adresi',
          emailLabelFlow: 'E-posta',
          countryLabel: 'Ülke',
          firstnameLabel: 'Ad',
          firstnamePlaceholder: 'isim',
          firstnameLabelFlow: 'Ad',
          lastnameLabel: 'Soyadı',
          lastnamePlaceholder: 'soyadı',
          lastnameLabelFlow: 'Soyadı',
          phoneLabel: 'Telefon numarası',
          phonePlaceholder: 'telefon numarası',
          phoneLabelFlow: 'Telefon',
          countryCodePlaceholder: 'kod',
          countryCodeLabelFlow: 'Kod',
          trustedLabel: 'Bu cihazı güvenilenler listenize ekleyin<br><span>Bir dahaki sefere bu cihazı tanıyacağız ve giriş yapmadan ödeme yapacaksınız</span>',
          termsLabel: 'Devam ederek <a href="https://www.mastercard.com/global/click-to-pay/country-listing/terms.html" target="_blank">Kullanım Şartlarını</a> kabul etmiş olursunuz a ve verilerinizin <a href="https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html" target="_blank">Politika gizliliği</a> a Mastercard.',
          c2pInfo: 'Click to Pay bu bilgiyi seni doğrulamak ve bu numaraya doğrulama kodları göndermek için kullanacak. Mesaj/veri ücretleri geçerli olabilir.',
          butonuSubmit: 'Kaydet ve öde',
          ButtonBack: 'Kartınızı kaydetmeden ödeyin'
        },
        profile: {
          header: 'Click to Pay\'te oturum açın',
          buttonChangeEmail: 'E-posta adresini değiştir'
        },
        modal: {
          title: 'Click to Pay - bilgi',
          header: 'Yeni Click_to_Pay standardında kart ayrıntılarınızı yeniden yazmaya gerek kalmadan hızlı ve güvenli bir şekilde ödeme yapın',
          text1: 'Kartla ödeme yaparken <img src="/web/img/click2pay/Click_to_Pay.svg" style="height: 14px" alt="Tıkla Ödeme Logosu"> logosunu arayın ve ödeme kartınızla ödeme yapın hızlı ve kolay bir şekilde.',
          text2: 'Tıkla ve Öde, Visa ve Mastercard tarafından desteklenen yeni bir kartlı ödeme standardıdır.',
          text3: 'Polonya\'daki ve yurt dışındaki mağazalarda, Kart verilerini kopyalamadan, Tıkla ve Öde hesabınızdaki kartla ödeme yapabilirsiniz.',
          text4: 'Veriler Visa veya Mastercard\'da güvenli bir şekilde saklanıyor. Tüm bilgiler tamamen şifrelenmiştir.',
          text5: 'Sonraki ödemeler sırasında Tıkla ve Öde e-posta adresinizi tanıyacaktır, böylece kayıtlı kartlarınızla hemen ödeme yapabilirsiniz.',
          text6: 'Ayrıca ödemeleri bankanızla onaylarsınız, bu da tam güvenliği garanti eder.',
          ProcessError: 'Tıkla ve Öde ödemesi yürütülürken bir hata oluştu. Standart kartla ödeme yapın.'
        }
      },
      email: {
        label: 'E-posta adresinizi girin',
        flowLabel: 'E-posta',
        tip: 'Size ödeme durumu ile ilgili mesajlar gönderebilmemiz için e-posta adresinize ihtiyacımız var.'
      },
      terms: {
        header: 'Onaylar ve beyanlar',
        selectAll: 'Hepsini seçiyorum',
        fieldNotRequired: 'Zorunlu alan',
        partnerMarketingTextLess: 'Bizim veya Ortaklarımız tarafından düzenlenen en iyi teklifler, promosyonlar, yarışmalar ve diğer ilgi çekici etkinlikler hakkında e-posta, SMS veya telefon bildirimleri almak ister misiniz?',
        partnerMarketingTextMore: 'Spam göndermeyeceğimize söz veriyoruz! Mesajlarımız hoşunuza gitmezse, onayınızı istediğiniz zaman geri çekebilirsiniz.',
        tradeMarketingTextLess: 'Size ilgi çekici bilgiler ve teklifler sunabilmeleri için Güvenilir Ortaklarımıza e-postanızı veya telefon numaranızı verebilmemizi ister misiniz?',
        tradeMarketingTextMore: 'İş Ortakları grubumuzun, bizim gibi spam yapmaktan hoşlanmayan tanınmış şirketlerden oluştuğunu ve onayınızı istediğiniz zaman geri çekebileceğinizi temin ederiz.'
      },
      termsLabelDefault: 'Gerekli bilgi aldım ve kabul ederim <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Ödeme hizmeti sunma hizmet yönetmeliğini içeren dosyayı indir" rel="noreferrer">tüzük</a> ve <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Gizlilik politikası Autopay S.A.">Gizlilik politikası</a> Autopay S.A. gizlilik politikası içerikli dosyayı indir. Hizmetin derhal gerçekleşmesini isterim, anlaşmadan caymam halinde – isteğim üzerine cayma anına kadar gerçekleşmiş hizmetlere ilişkin giderlerin tarafıma iade edilmeyeceğini kabul ederim.',
      termsReccuring: 'Ödeme yapmak istersen <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer">tüzük</a> i <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="hizmet yönetmeliğini içeren dosyayı indir. Autopay S. A.">Gizlilik politikası</a> Autopay S.A. dosyasını indir, ödeme hizmetinin derhal gerçekleşmesine rızayı indir ödeme kartına giderin yükletilmesine rızayı indir.',
      termsReccuringMore1: 'Kabul ederim ki hizmet derhal yapılacak, anlaşmadan caymam halinde bilirim ki isteğim üzerine cayma anına kadar gerçekleşmiş hizmetlere ilişkin giderler tarafıma iade edilmeyecek.',
      termsReccuringMore2: 'Kabul ederim ki Autopay S. A. {receiverName} lehine işlemin gerçekleşmesi için ilgili giderleri ödeme kartıma yükletilecek. Kartın dönemsel yükletilmesine ilişkin kuralları <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Hizmet yönetmeliğini içeren dosyayı indir" rel="noreferrer">Yönetmelik</a> ödeme hizmetlerinin gerçekleşmesi belirler. Beyan ederim ki ödeme kartıma ilişkin yükletme tutarlarının süreleri, sıklığı ve tespit kurallarının yanı sıra işbu rızamdan (tarafımdan {receiverName} ile tespit edilmiş) cayma kuralını biliyorum. Ödeme kartının dönemsel olarak yükletilmesi ile ilgili soru olma halinde {receiverName} ile temasa geçeceğim.',
      mwfClauseLess: `Ödeme yapmak istiyorsanız <a href="{regulationUrl}" class="form-text-link" target="_blank" title="Dosyayı şartlar ve koşullarla indir" rel="noreferrer">Şartlar ve koşullar</a> ve <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="Autopay S.A. gizlilik politikası dosyasını indirin.">Autopay S.A. gizlilik politikası</a>, Autopay S.A. aracılığıyla bankanızda işlem başlatmak için onay verin. ve bu işlemin detaylarının alıcıya iletilmesi ve ödeme hizmetinin derhal yerine getirilmesinin talep edilmesi.`,
      mwfClauseMore: 'Hizmetin hemen verilmesini istiyorum ve sözleşmeden dönersem geri ödeme almayacağımı biliyorum.',
      mwfClauseARIA: `Ödeme yapmak istiyorsanız, Autopay S.A.'nın Yönetmeliklerini ve gizlilik politikasını kabul edin, Autopay S.A. aracılığıyla bankanızda işlem başlatmaya, ve bu işlemin detaylarının alıcıya iletilmesi ve ödeme hizmetinin derhal yerine getirilmesi talebine onay verin. Hizmetin derhal verilmesini istiyorum ve sözleşmeden cayma durumunda geri ödeme almayacağımı biliyorum. <a href="{regulationUrl}" class="form-text-link" target="_blank" title="" rel="noreferrer" tabindex="-1">Hizmet şartlarını içeren dosyayı indirin</a> <a href="{privacyPolicyUrl}" class="form-text-link" target="_blank" title="" tabindex="-1">Dosyayı Autopay S.A. gizlilik ilkesinden indirin</a>`,
      paymentInfo: 'Ödeme talimatı, Sopot merkezli Autopay S.A. aracılığıyla gönderilir ve bankanız tarafından belirlenen şartlara uygun olarak gerçekleştirilecektir. Bankayı seçtikten sonra ödemeye onay vereceksiniz.',
      changePaywayLink1: 'Hala hakkınız var',
      changePaywayLink2: 'ödeme yöntemini değiştirmeye',
      linkMore: 'DAHA FAZLA',
      linkShowMoreTitle: 'Daha fazla bilgi göster',
      linkLess: 'DAHA AZ',
      linkShowLessTitle: 'İlave bilgileri gizle',
      submitButton: {
        visaMobile: 'Ile ödeme yapın',
        fastTransfer: 'Havale verilerini oluştur',
        mwf: 'Kabul ediyorum ve ödüyorum',
        pis: 'Kabul ediyorum ve ödemeye başlıyorum',
        auto: 'Yinelenen ödeme talimatını ver',
        autoPayment: 'Sipariş ödeme',
        other: 'Ödüyorum',
        orange: 'Onaylamak',
        c2pNext: 'Sonraki',
        c2pAccept: 'Kabul ediyorum',
        c2pSaveAndPay: 'Kaydet ve öde'
      }
    },
    frData: {
      pageTitle: 'Aktarım için ayrıntılı veriler',
      header: 'Havaleyi verileri kullanarak gerçekleştir',
      nrbLabel: 'Alıcının hesabı',
      nameLabel: 'Alıcının adı',
      addressLabel: 'Alıcının adresi',
      titleLabel: 'Havale Başlığı',
      amountLabel: 'Havale tutarı',
      timeLabel: 'Havalenin gideceği taraf',
      linkCopy: 'Kopyala',
      linkGetPdf: `Verileri içeren PDF'yi indir`,
      linkGetPdfTitle: 'Yeni bir pencerede açılır',
      linkGoToBank: 'Bankaya giriş yap',
      info: 'Banka hesabınıza giriş yapın. Verileri kopyalayıp aktarıma yapıştırın. Verilerin aynı olması önemlidir, tutar, para birimi, unvan vb. değiştirmeyin.'
    },
    processing: {
      pageTitle: 'İşlem işleme için bekleyen sayfa',
      loaderAnimation: 'Beklerken görüntülenen animasyon',
      header: 'Ödeme sayfanız hazırlanıyor.',
      description: 'Biraz bekleyin...',
      info: {
        header: 'Unutmayın!',
        description: 'İşlemi tamamladıktan sonra sayfayı kapatmayın - kapatırsanız paranız alıcıya gönderilmez.'
      },
      loaderText: 'Veriler yükleniyor.<br> Bu, 30 saniyemizi alacaktır'
    }
  },
  thankYou: {
    pageTitle: 'İşlemin sonucu hakkında bilgi',
    form: {
      emailLabel: 'E-posta',
      buttonSubmit: 'Partnerin sayfasına geri dön'
    },
    negative: {
      header: 'İşlem yetkilendirmesi eksikliğinden dolayı ödeme başarısız oldu.',
      description: 'Belirli bir satıcının veya hizmet sağlayıcının teklifinden hala faydalanmak istiyorsanız - web sitelerine geri dönün, ürünü veya hizmeti seçin ve istediğiniz yöntemle ödeme yapın.'
    },
    pending: {
      header: 'Ödeme ağ geçidi aracılığıyla ödeme talimatı verdiğiniz için teşekkür ederiz',
      description: 'İşlem durumu doğrulanıyor. İşlem tamamlandığında, e-posta adresinize ödeme durumu ile ilgili bilgi gönderilecektir.'
    },
    positive: {
      header: 'Teşekkürler, ödemeniz işleme alındı.',
      description: 'Onay, verdiğiniz e-posta adresine gönderilecektir.'
    }
  },
  regulationsApproval: {
    header: 'İşlemi kabul et'
  },
  wait: {
    pageTitle: 'Sayfa bekleniyor'
  },
  sessionTimeout: {
    pageTitle: 'Oturumun bitiminden sonraki sayfa',
    header: 'Ödemeyi gerçekleştirme süresi doldu',
    description: 'Dilediğiniz zaman partneri web sitesine dönebilir, hizmeti yeniden sipariş edebilir veya kullanabilir ve istediğiniz yöntemle ödeme yapabilirsiniz.'
  },
  paywayError: {
    pageTitle: 'Seçilen ödeme kanalı kullanılamıyor',
    header: 'Maalesef, seçilen ödeme yöntemi şu anda kullanılamıyor.',
    description: 'Lütfen ortak sayfasına dönün ve daha sonra tekrar deneyin.'
  },
  loader: {
    defaultAnimation: 'Sayfa yüklenirken görüntülenen animasyon',
    slider: 'Değişen metinlerle kaydırıcı',
    text1: `Autopay'da karbon nötrlüğü için çalışıyoruz. Też to hizmetlerimizi kullanarak yaparsınız.`,
    text2: `Autopay'da işe bisikletle gidip atmosfere salınan sera gazı emisyonlarını azaltıyoruz. Siz de hizmetlerimizi kullanarak bunu yapın.`,
    text3: `Autopay'da gücümüzü yeşil enerjiden alıyoruz. Hizmetlerimizi kullandığınızda siz de.`,
    text4: `Autopay'da kağıt tüketimini sınırlıyoruz. Też to  hizmetlerimizi kullanarak siz de sınırlandırırınız.`,
    rateLimitExceeded: 'There is increased transaction traffic, so processing your payment may take longer than usual. Please wait and do not refresh the payment page.'
  },
  orderDetails: {
    header: 'Ayrıntılar',
    headerAriaLabel: 'İşlem ayrıntıları',
    amountLabel: 'Ödenmesi gereken tutar',
    receiverLabel: 'Alıcı',
    orderLabel: 'Sipariş numarası',
    amountOryginalCurrencyLabel: '{currency} cinsinden tutar',
    currencyRateLabel: 'Döviz kuru',
    feeLabel: 'İşlem komisyon ücreti',
    customerNumberLabel: 'Müşteri Numarası',
    invoiceNumberLabel: 'Fatura numarası',
    accountNumberLabel: 'Hesap numarası',
    paywayLabel: 'Ödeme yöntemi',
    paywayName: 'İnternet havalesi',
    linkBack: 'Mağazaya dön',
    linkBackTitle: 'İşlemden ayrıl ve mağazaya dön',
    linkMore: 'daha fazla',
    linkLess: 'daha az',
    toggleBtnUnfoldAriaLabel: 'Sipariş ayrıntılarını aç',
    toggleBtnFoldAriaLabel: 'Sipariş ayrıntılarını daralt'
  },
  error: {
    pageTitle: 'Hata sayfası',
    linkBackUrlSet: 'Ortak sayfasına dön',
    linkBackUrlNotSet: 'Destek sayfasına git',
    sessionTimeout: {
      title: 'Ödemeyi gerçekleştirme süresi doldu',
      message: 'Dilediğiniz zaman partneri web sitesine dönebilir, hizmeti yeniden sipariş edebilir veya kullanabilir ve istediğiniz yöntemle ödeme yapabilirsiniz.'
    },
    notFoundError: {
      pageTitle: 'Hata sayfası 404 - sayfa bulunamadı',
      title: 'Görünüşe göre çok ileri gittiniz ve ziyaret etmek istediğiniz sayfayı bulamadınız.',
      message: 'Olası nedenler: sayfa mevcut değil veya kaldırılmış, bakım çalışması yapılıyor, sayfanın adresi yanlış, teknik bir hata oluştu.'
    },
    generalError: {
      title: 'Üzgünüz',
      message: 'Şu anda işleminizi gerçekleştiremiyoruz.'
    },
    errFieldNotFound: {
      title: 'Üzgünüz',
      message: ''
    },
    errBadClientSource: {
      title: 'Üzgünüz',
      message: ''
    },
    nrParametersError: {
      title: 'Üzgünüz',
      message: 'Şu anda işleminizi gerçekleştiremiyoruz.'
    },
    transactionOutdated: {
      title: 'Ödemeyi gerçekleştirme süresi doldu',
      message: 'Yeniden talimat verin.'
    },
    linkValidityTimeOutdated: {
      title: 'Ödemeyi gerçekleştirme süresi doldu',
      message: 'Yeniden talimat verin.'
    },
    transactionValidityTimeOutdated: {
      title: 'Ödemeyi gerçekleştirme süresi doldu',
      message: 'Yeniden talimat verin.'
    },
    multiplyTransaction: {
      title: 'Üzgünüz',
      message: 'İşlem zaten mevcut ve ödeme bekleniyor.'
    },
    transactionCanceled: {
      title: 'Üzgünüz',
      message1: 'İşlem iptal edildi.',
      message2: 'Yeniden talimat verin.'
    },
    multiplyPaidTransaction: {
      title: 'Üzgünüz',
      message: 'Ödeme işlemi zaten gerçekleşti.'
    },
    transactionReject: {
      title: 'Üzgünüz',
      message1: 'Hata, bir şeyler ters gitti.',
      message2: 'Hatayı hemen düzeltiyoruz...'
    },
    bankDisabled: {
      title: 'Üzgünüz',
      message: 'Seçilen banka şu anda kullanılamıyor.'
    },
    bankTemporaryMaintenance: {
      title: 'Üzgünüz',
      message: 'Şu anda işleminizi gerçekleştiremiyoruz.'
    },
    insufficientStartAmount: {
      title: 'Üzgünüz',
      message: 'Havale tutarının limiti aşıldı.'
    },
    startAmountOutOfRange: {
      title: 'Üzgünüz',
      message: 'Havale tutarının limiti aşıldı.'
    },
    nonAccountedLimitExceeded: {
      title: 'Üzgünüz',
      message: 'Satıcı aylık satış kotasına ulaştığı için ödeme tamamlanamadı.'
    }
  },
  confirmation: {
    pageTitle: 'Ödeme ilerleme bilgileri',
    linkBack: 'Siteye geri dönüyorum',
    linkBackTimeout: 'İş ortağının web sitesine geri dön ',
    incorrectAmount: {
      header: 'Yanlış tutar',
      description: 'Tutar hatalı olduğundan ödemenizi alıcıya aktarmadık.',
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın. Hiçbir veriyi değiştirmemeyi unutmayın.'
    },
    multiplePaid: {
      header: 'Çifte para yatırma',
      description: 'Alıcı ödemenizi iki kez aldı. Endişelenmeyin; geri ödemenizi alacaksınız.',
      description2: 'Alıcıyla iletişime geçin ve ona ödeme onayını sağlayın.'
    },
    negative: {
      header: 'Zamanın sonu',
      description: 'Onay süresi dolduğu için ödemenizi alıcıya aktarmadık.',
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın.'
    },
    notFound: {
      header: 'Ödeme yok',
      description: 'Ödemeniz işlenirken bir hata oluştu. Ama hiçbir şey kaybolmadı.',
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın.'
    },
    outdated: {
      header: 'Zamanın sonu',
      description: 'Ödemenizi alıcıya aktarmadık.',
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın.'
    },
    outdatedPaidNotRefunded: {
      header: 'Zamanın sonu',
      description: `Ödemenizi alıcıya aktarmadık. Hesabınızdan para çekildiyse endişelenmeyin, birkaç gün içinde geri ödemenizi alacaksınız.`,
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın.'
    },
    outdatedPaidRefunded: {
      header: 'Zamanın sonu',
      description: 'Ödemenizi alıcıya aktarmadık. Para hesabınızdan çekildiyse endişelenmeyin, parayı zaten iade ettik.',
      description2: 'Sayfaya dönmek ve işlemi yeniden yapmak için düğmeye tıklayın.'
    },
    pending: {
      header: 'Devam ediyor',
      description: 'Ödemenizi gerçekleştiriyoruz. Alıcıya ulaştığında sizi bilgilendireceğiz.'
    },
    positive: {
      header: 'Ücretli',
      visaVideo: 'Başarılı bir işlemden sonra oynatılan reklam videosu',
      description: 'Paranızı alıcıya aktardık.'
    }
  },
  messages: {
    accessForbidden: 'Erişim yok',
    internalServerError: 'Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.',
    validators: {
      required: 'Zorunlu alan',
      pattern: 'Lütfen geçerli bir değer girin',
      emailRequired: 'Geçerli bir e-posta adresi girin',
      emailNotValid: 'Geçerli bir e-posta adresi girin',
      emailNotRecognized: 'Verilen e-posta adresi kayıtlı değil',
      emailIdentityLookup: 'E-posta adresi doğrulanamadı',
      emailLocked: 'Verilen e-posta adresi Mastercard sisteminde geçici olarak engellendi',
      paywayRequired: 'Ödeme yapmak için bir banka seçin',
      creditCardRequired: 'Lütfen tüm alanları doldurun',
      phoneRequired: 'Geçerli bir telefon numarası giriniz',
      regulationRequired: 'Yönetmeliği kabul edin',
      requiredConsent: 'Onay gerekli',
      codeNotValid: 'Girilen kod geçerli değil',
      codeAccountLocked: 'Hesap Mastercard sisteminde geçici olarak bloke edildi'
    },
    validatorsAria: {
      required: 'Uyarı. Form alanı doldurulmadı. Gerekli alan.',
      pattern: 'Dikkat. Alan geçersiz bir değer içeriyor.',
      emailRequired: 'Uyarı. E-posta alanı doldurulmadı. Geçerli bir e-posta adresi girin.',
      emailNotValid: 'Uyarı. Girdiğiniz e-posta adresi hatalı. Geçerli bir e-posta adresi girin.',
      emailNotRecognized: 'Dikkat. Verilen e-posta adresi kayıtlı değil.',
      emailIdentityLookup: 'Dikkat. Sağlanan e-posta adresi doğrulanamadı',
      emailLocked: 'Dikkat. Verilen e-posta adresi Mastercard sisteminde geçici olarak bloke edilmiştir.',
      paywayRequired: 'Uyarı. Ödeme yöntemi seçilmedi. Ödeme yöntemini seçin.',
      creditCardRequired: 'Dikkat. Lütfen tüm kredi kartı alanlarını bilgileriniz ile doldurun.',
      phoneRequired: 'Dikkat. Telefon numarası alanında hata var. Doğru telefon numarası gir.',
      codeNotValid: 'Dikkat. Sağlanan kod geçerli değil.',
      codeAccountLocked: 'Dikkat. Hesap Mastercard sisteminde geçici olarak bloke edilmiştir.'
    },
    success: {
      copy: 'Kopyalandı!',
      codeSent: 'Kod gönderildi!'
    },
    errors: {
      getPdf: 'PDF dosyası indirilemedi!',
      copy: 'Kopyalama işlemi başarısız!',
      thankYouUpdate: 'E-posta adresi kaydedilemedi!',
      groupInactive: 'Şu anda {groupName} grubunu kullanarak aktarım yapmak mümkün değildir. Lütfen başka bir ödeme yöntemi seçin.',
      paywayInactive: 'Şu anda seçilen banka üzerinden işlem yapmak mümkün değil. Lütfen farklı bir banka veya ödeme yöntemi seçin.',
      googlePayInactive: 'Maalesef, Google Pay ile ödeme cihazınızda kullanılamıyor.',
      applePayInactive: 'Apple Pay ödeme yöntemi, Safari ve iOS cihazları kullanan kişiler tarafından kullanılabilir.',
      codeNotSent: 'Kod yeniden gönderilemedi!',
      codeCounterExceeded: 'İzin verilen deneme sayısı aşıldı. Kart bilgilerinizi manuel olarak girin veya başka bir ödeme yöntemi kullanın.'
    }
  },
  modal: {
    closeButton: 'Kapalı',
    confirmButton: 'Anlıyorum'
  },
  configuration: {
    paywayGroups: {
      auto: {
        label: 'Yenilenen ödeme',
        description: 'Ödeme için kullanacağınız kartınızın ayrıntılarını girin. Otomatik ödemeler daha sonra kartınızdan çekilecektir.',
        labelInitWithRefund: 'Doğrulama ödemesi',
        labelInitWithRefundOrange: 'Ödeme kartı ekle',
        labelInitWithPayment: 'Kartla ödeme',
        descriptionInitWithRefund: 'Gelecekteki ödemeler itibari ile verifye etmek istediğin kartın verilerini gir. Bir sonraki adımda ibraz edilen karttan – verileri doğrulamak için – 1 PLN alacağız. Doğrulama bitince bahse konu tutarı iade edeceğiz.',
        descriptionInitWithPayment: 'İşlem yapacağın kartın verilerini gir, bu şekilde kartını gelecekteki ödemeler itibari ile verifiye edersin’.'
      },
      blik: {
        label: 'BLIK',
        description: 'Giriş yapmadan veya kayıt olmadan BLIK kodunu girerek anında ödeme yapın'
      },
      card: {
        label: 'Kart ile ödeme',
        description: 'Ödeme kartınızla ödeme yapın'
      },
      pbl: {
        label: 'İnternet havalesi',
        description: 'Ödeme yapmak istediğiniz bankayı seçin',
        descriptionVerify: 'Çevrimiçi kimlik doğrulaması',
        descriptionServiceVerify: 'Çevrimiçi hizmeti doğrulamanıza izin verecek bankayı seçin'
      },
      visaMobile: {
        label: 'Visa Mobile',
        description: 'Vize Mobil ödeme'
      },
      wallet: {
        label: 'Sanal Cüzdan',
        description: 'İnternet bankacılığına giriş yapmadan ödeme yapın'
      },
      installments: {
        label: 'Taksitlere bölün',
        description: 'Alışverişlerinizi uygun taksitlerle ödeyin'
      },
      alior: {
        descriptionInfo1: 'Alışverişlerinizi uygun taksitlerle ödeyin',
        descriptionInfo2: '(ayrıntıları kontrol et)',
        aliorModal: {
          text1: 'Satın alma işlemini gerçekleştirdiğiniz sitenin ayarlarına bağlı olarak ürünleri aşağıdaki varyantlarda taksitli olarak satın alabilirsiniz:',
          text2: '<ul><li>10 taksit %0</li><li>20 taksit %0</li><li>3 ila 48 taksit ve aylık maliyet %1 (verilen temsili örneğe göre) belirli bir işlem için)</li></ul>'
        }
      },
      otp: {
        label: 'Daha sonra ödeyin',
        description: 'Şimdi al sonra öde',
        descriptionInfo1Mwf: 'Faturanızı daha sonra ödeyin - tek seferde 45 güne kadar veya birkaç eşit taksitle',
        descriptionInfo1Ecommerce: 'Daha sonra ödeme yapın - tek seferde 45 güne kadar veya birkaç eşit taksitle.',
        descriptionInfo2Mwf: 'Kredi aracısı ve maliyeti hakkında bilgi',
        descriptionInfo2Ecommerce: 'Maliyet bilgileri',
        labelPayka: 'Payka',
        descriptionPayka: 'Hesap ücretini – bir defa ödeme yapmak sureti ile 30 gün içerisinde veya 4 eşit taksit halinde <a href="" id="paykaShowInModal">Kredi aracısı ve maliyeti hakkında bilgi</a>',
        descriptionBlikPayLater: 'Hizmet Millenium Bank ve VeloBank\'ta mevcuttur.',
        paykaModalMwf: {
          header1: 'Kredi aracısı ve maliyeti hakkında bilgi',
          text1: 'Sopot’ta yerleşik Autopay S. A. Anonim Şirketi, Gdańsk’ta yerleşik Paytree S. A. Anonim Şirketinin kredi aracısı olup müşterilere kredi teklifinde bulunmaya yetkili şirkettir. Bu bağlamda kredi istemi dâhil kredi verenin internet servisine müşteriyi yönlendirmeye de yetkilidir. Teklif ayrıntıları: <a href="https://payka.pl/rachunki-domowe" target="_blank">Rachunki domowe - Payka</a>.',
          header2: 'Ücret hakkındaki bilgi',
          text2: 'Daha sonra ödeme yapın - tek seferde 45 güne kadar veya birkaç eşit taksitle. Temsili bir örnek için maliyet verileri: 45 gün içinde geri ödeme Nisan %0; Taksit geri ödemesi Nisan 86,83. Teklifin ayrıntıları şu adrestedir: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        paykaModalEcommerce: {
          header2: 'Ücret hakkındaki bilgi',
          text2: 'Daha sonra ödeme yapın - tek seferde 45 güne kadar veya birkaç eşit taksitle. Temsili bir örnek için maliyet verileri: 45 gün içinde geri ödeme Nisan %0; Taksit geri ödemesi Nisan 86,83. Teklifin ayrıntıları şu adrestedir: <a href="https://payka.pl" target="_blank">Payka.pl</a>'
        },
        blikPayLaterModal: {
          header1: 'şimdi satın al, 30 gün içerisinde öde',
          header2: 'aktivasyon',
          text2: 'BLIK sonra öderim opsiyonu seçersin, BLIK kodu girersin, biz senin için alışveriş limiti tespit ederiz.<br>Maksimum olarak bu, 4000 zł’dır',
          header3: 'Alış veriş',
          text3: 'Alışveriş anında ödemediğin ürünleri satın alıyorsun. Bütün bunların senin için uygun olup olmadığını kontrol etmek için zamanın var.',
          header4: 'Her alışverişten sonra',
          text4: 'Ek masraf olmadan 30 gün içerisinde ödeme yaparsın. Ayrıca satın aldığın ürünleri iade edebilirsin veya ödemeyi 3 takside bölebilirsin.'
        }
      },
      companyPayments: {
        label: 'Płatności firmowe',
        description: 'Przesuń termin płatności za zakupy firmowe o 30 dni'
      },
      transfer: {
        label: 'Havale bilgileri',
        description: 'Verilen bilgileri kullanarak siparişinizin havalesini gerçekleştirin'
      },
      other: {
        label: 'Diğer',
        description: ''
      }
    },
    mobileEvents: {
      statusDescription: {
        bankDisabled: 'Üzgünüz. Şu anda işleminizi gerçekleştiremiyoruz. Seçilen banka şu anda kullanılamıyor.',
        blockMultipleTransactions: 'Üzgünüz. İşlem zaten mevcut ve ödeme bekleniyor.',
        blockPaidTransactions: 'Üzgünüz. İşlemin ödemesi zaten gerçekleşti. Yeniden ödeme yapamazsınız.',
        canceledTransactions: 'Ödemeye çalıştığınız işlem iptal edildi ve artık kullanılamıyor. Lütfen yeni bir işlem başlatın.',
        declined: 'Yetkilendirme reddedildi.',
        generalError: 'Geçici bir bağlantı sorunu oluştu. Ödeme talimatınızı şu anda işleme alamıyoruz. Lütfen daha sonra tekrar deneyin.',
        insufficientStartAmount: 'Üzgünüz. İşlem tutarı limiti aşıldı.',
        internalServerError: 'Eyvah.. İşlem başarısız :(. Bir düzeltme üzerinde çalışıyoruz. Lütfen daha sonra yeniden deneyin.',
        nonAccountedLimitExceeded: 'Satıcı aylık satış limitine ulaştığı için ödeme gerçekleştirilemedi.',
        outdatedError: 'Ödemeyi tamamlama süresi aşıldı.',
        paid: 'İşlem başarıyla tamamlandı.',
        paywayLoaded: 'Hızlı transfer sayfası yüklendi',
        paywaylistLoaded: 'Ödeme yöntemi seçim sayfası yüklendi'
      }
    }
  },
  maintenance: {
    text1: 'Autopay ödeme ağ geçidinde Yeni Yıl temizliği yapılıyor, bu nedenle şu anda ödeme talimatı vermek mümkün değil. Verdiğimiz rahatsızlıktan dolayı özür dileriz.',
    text2: `Ağ geçidi 16 Ocak saat 10:00'da tekrar çalışır durumda olacak.`,
    text3: 'Anlayışınız için teşekkür ederiz.'
  },
  requiredParams: {
    accountHolderName: {
      label: 'Bana adını ve soyadını ver',
      flowLabel: 'Ad ve soyad',
      placeholder: 'Bana adını ve soyadını ver'
    },
    nip: {
      label: 'Vergi Kimlik Numaranızı girin',
      flowLabel: 'NIP',
      placeholder: 'Vergi Kimlik Numaranızı girin'
    }
  }
}
